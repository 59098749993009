import { ReactElement } from 'react';

const ForgotPassword = (): ReactElement => {
  return (
    <>
      ForgotPassword Page
    </>
  )
}

export default ForgotPassword